import { useEnvironmentSetting, useLicenseFeature, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const ProjectPurchasingMenuItem = () => {
  const { t } = useTranslation("main_menu");

  const hasProjectPurchasingPermission = usePermission("projects", "projectpurchase").granted;
  const hasAppropriateLicense = useLicenseFeature("purchase_costs").enabled;

  const projectPurchaseEmployeeEnabled =
    useEnvironmentSetting("projects", "is_use_project_purchase_employee")?.value === 1;
  const projectPurchaseInvoiceEnabled =
    useEnvironmentSetting("projects", "is_use_project_purchase_invoice")?.value === 1;
  const projectPurchaseCostsEnabled = useEnvironmentSetting("projects", "is_use_project_purchase_costs")?.value === 1;

  const showProjectPurchaseMenuItem =
    projectPurchaseEmployeeEnabled || projectPurchaseInvoiceEnabled || projectPurchaseCostsEnabled;

  if (!hasAppropriateLicense || !hasProjectPurchasingPermission || !showProjectPurchaseMenuItem) {
    return null;
  }

  return (
    <NavMenu.SubItem
      href="/projects/purchasing"
      title={t("projects_purchasing")}
      testId="projects-purchasing-menu-item"
    />
  );
};
