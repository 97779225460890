import { useCreateServiceGroupMutation } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Button, Input, Modal, TextArea } from "@simplicate/ui";
import { useState } from "react";
import styles from "./AddServiceGroupModal.module.scss";

type AddServiceGroupModalProps = {
  salesId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const AddServiceGroupModal = ({ salesId, onClose, isOpen }: AddServiceGroupModalProps) => {
  const { t } = useTranslation("sales_services");
  const [createServiceGroup] = useCreateServiceGroupMutation();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleCreateServiceGroup = async () => {
    if (!name) return;

    await createServiceGroup({
      salesId: salesId,
      name: name,
      description: description,
    });

    onClose();
    setName("");
    setDescription("");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form
        className={styles.modalContent}
        onSubmit={(e) => {
          e.preventDefault();
          void handleCreateServiceGroup();
        }}
      >
        <h3>{t("modal_title")}</h3>
        <Input
          type="text"
          name="name"
          label={t("service_group.name_label")}
          // required
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextArea
          name="description"
          label={t("service_group.description_label")}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
        <div className={styles.modalButtonsWrapper}>
          <Button variant="primary" size="medium" text={t("button_add")} type="submit" />
          <Button variant="subtle" size="medium" text={t("button_cancel")} onClick={onClose} />
        </div>
      </form>
    </Modal>
  );
};
