import { RenderParams } from "@minoru/react-dnd-treeview";
import { useTranslation } from "@simplicate/translations";
import classNames from "classnames";
import { ServiceGroupNodeModel, ServiceGroupType } from "../../GroupedServicesManager.types";
import { ServiceGroupHeader } from "../ServiceGroupHeader";
import { ServiceRowHeader } from "../ServiceRowHeader";
import styles from "./ServiceGroup.module.scss";

type ServiceGroupProps = Partial<RenderParams> & {
  node: ServiceGroupNodeModel;
  onDuplicateGroup?: () => void;
  onAutoGroup?: () => void;
};

export const ServiceGroup = ({
  node: { text, data },
  hasChild,
  isDropTarget,
  onDuplicateGroup,
  onAutoGroup,
}: ServiceGroupProps) => {
  /*istanbul ignore next*/
  const isUngroupedContainer = data?.groupType === ServiceGroupType.UNGROUPED;
  const { t } = useTranslation("grouped_services_manager");

  return (
    <div className={styles.serviceGroup}>
      <ServiceGroupHeader
        text={text}
        isUngroupedContainer={isUngroupedContainer}
        onDuplicateGroup={onDuplicateGroup}
        onAutoGroup={onAutoGroup}
        deleteCallback={/*istanbul ignore next*/ data?.deleteCallback}
      />
      <ServiceRowHeader />
      {!hasChild && (
        <div className={classNames(styles.emptyMessage, { [styles.dropTarget as string]: isDropTarget })}>
          {t("empty_service_group_message")}
        </div>
      )}
    </div>
  );
};
