import { Money } from "@simplicate/api-client/src/types/money";
import { simplicateApiV2, simplicateApiV3 } from "../components";
import { createTagTypes } from "../tags";

export type Service = {
  hoursTotalAmount: number;
  hoursTotalBudget: Money;
  id: string;
  invoiceMethod: string;
  name: string;
  price: Money;
  purchaseTotalBudget: Money;
};

export type ServiceGroup = {
  description: string;
  id: string;
  name: string;
  services: Service[];
};

export type Sales = {
  id: string;
  name: string;
  groupedServices: ServiceGroup[];
  ungroupedServices: Service[];
};

export type CreateServiceGroupBody = {
  salesId: string;
  name: string;
  description: string;
};

export type DeleteServiceGroupBody = {
  salesId: string;
  serviceGroupId: string;
  shouldDeleteServices: boolean;
};

export type DuplicateServiceBody = {
  serviceId: string;
};

export type ChangeServiceTreeBody = {
  salesId: string;
  serviceGroupStructure: {
    structureForGroups: {
      groupId: string;
      serviceIds: string[];
    }[];
    ungroupedServiceIds: string[];
  };
};

const tags = createTagTypes({
  tagPrefix: "Sales",
  tags: ["list", "sale"],
});

const endpointsV3 = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getSales: builder.query<Sales, string>({
      query: (id) => ({
        url: `/sales.opportunity.get/${id}`,
      }),
      providesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),
    createServiceGroup: builder.mutation<Sales, CreateServiceGroupBody>({
      query: (body) => ({
        url: `sales.opportunity.addServiceGroup`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),
    deleteServiceGroup: builder.mutation<Sales, DeleteServiceGroupBody>({
      query: (body) => ({
        url: `sales.opportunity.deleteServiceGroup`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),
    duplicateService: builder.mutation<Sales, DuplicateServiceBody>({
      query: (body) => ({
        url: `sales.opportunity.duplicateService`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),
    changeServiceTree: builder.mutation<Sales, ChangeServiceTreeBody>({
      query: (body) => ({
        url: `sales.opportunity.changeServiceTree`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),

    autoGroupServices: builder.mutation<Sales, string>({
      query: (salesId) => ({
        url: `sales.opportunity.autoGroupServicesByInvoiceMethod`,
        body: { salesId },
        method: "POST",
      }),
      invalidatesTags: (result, _error, salesId) =>
        result ? [{ type: tags.sale, id: salesId }, tags.list] : [tags.list],
    }),
  }),
});

export const {
  useGetSalesQuery,
  useCreateServiceGroupMutation,
  useDeleteServiceGroupMutation,
  useDuplicateServiceMutation,
  useChangeServiceTreeMutation,
  useAutoGroupServicesMutation,
} = endpointsV3;

const endpointsV2 = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    deleteSalesService: builder.mutation<null, string>({
      query: (id) => ({
        url: `sales/service/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _error, id) => (result ? [{ type: tags.sale, id: id }, tags.list] : [tags.list]),
      onQueryStarted: (arg, api) => {
        void api.queryFulfilled.then(() => {
          // @ts-expect-error -- See https://github.com/reduxjs/redux-toolkit/issues/2484.
          api.dispatch(simplicateApiV3.util.invalidateTags([{ type: tags.sale, id: arg }, tags.list]));
        });
      },
    }),
  }),
});

export const { useDeleteSalesServiceMutation } = endpointsV2;
