import { useEnvironmentSetting, useLicenseFeature } from "@simplicate/api-client";

export const useProjectReverseBillingTabPermissions = () => {
  const isSecondmentBranch = useEnvironmentSetting("environment", "branch_secondment")?.value === 1;
  const hasReverseBillingEnabled = useEnvironmentSetting("invoices", "is_reverse_billing")?.value === 1;

  const hasInvoiceOptionsLicense = useLicenseFeature("invoice_options").enabled;

  return {
    showReverseBillingTab: isSecondmentBranch && hasReverseBillingEnabled,
    disableReverseBillingTab: !hasInvoiceOptionsLicense,
  };
};
