import { useTranslation } from "@simplicate/translations";
import { TextArea } from "@simplicate/ui";
import { memo } from "react";

type ExplanationTextAreaProps = {
  value: string | undefined;
  error: string | undefined;
  touched?: boolean;
  onChange: (value: string) => void;
  disabled: boolean;
};

export const ExplanationTextArea = memo(function ExplanationTextArea({
  value,
  error,
  onChange,
  disabled,
  touched = false,
}: ExplanationTextAreaProps) {
  const { t } = useTranslation("project_services");

  return (
    <div style={{ gridRowStart: 3, gridRowEnd: 5 }}>
      <TextArea
        name="explanation"
        testId="explanation-text-area"
        label={t("explanation")}
        value={value}
        onChange={(event) => void onChange(event.target.value)}
        hasError={touched && error !== undefined}
        disabled={disabled}
      />
    </div>
  );
});
