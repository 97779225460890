import { simplicateApiV3 } from "../components";

export type v3Identifier = {
  v2Identifier: string;
  identifier: string;
};

const endpoints = simplicateApiV3.injectEndpoints({
  endpoints: (builder) => ({
    getV3Identifiers: builder.query<{ results: v3Identifier[] }, { v2Identifiers: string[] }>({
      query(body) {
        return {
          url: `/migration.v2IdentifiersToUuids`,
          body: body,
          method: "POST",
        };
      },
    }),
  }),
});

export const { useGetV3IdentifiersQuery } = endpoints;
