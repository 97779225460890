import { colorCommonGrey800, lightColorTextDefault } from "@simplicate-software/design-tokens";
import { Chart as ChartJS, ArcElement, Colors, ChartData, ChartOptions } from "chart.js";
import Annotation, { LabelAnnotationOptions } from "chartjs-plugin-annotation";
import { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import styles from "./Doughnut.chart.module.scss";

ChartJS.register(ArcElement, Colors, Annotation);
ChartJS.defaults.backgroundColor = "transparent";
ChartJS.defaults.borderColor = "transparent";
ChartJS.defaults.color = lightColorTextDefault;
ChartJS.defaults.elements.arc.borderWidth = 0;

type ChartConfigOptions = { centerText?: string };
type DoughnutChartProps<TData extends number[], TLabel> = ChartConfigOptions & {
  data: ChartData<"doughnut", TData, TLabel>;
};

const DefaultDoughnutOptions: ChartOptions<"doughnut"> = {
  /**
   * The donut chart is designed at 120x120 pixels (7.5rem).
   * By design the thickness of the donut is 16px (1rem).
   * The thickness should be subtracted twice from the overall size of the chart to get the size of the cutout.
   * Then we convert this to a percentage to make the thickness feel consistent across different sizes of charts.
   */
  cutout: `${100 - (2 / 7.5) * 100}%`,
  responsive: false,

  /**
   * Configure chartjs to render the chart in a 5x resolution compared to the device resolution. This improves the
   * quality of the chart when zoomed in on the page.
   */
  devicePixelRatio: 5,
};

function buildCenterAnnotation(
  centerText?: string,
): Record<string, LabelAnnotationOptions & { type: "label" }> | undefined {
  return centerText !== undefined
    ? {
        label1: {
          type: "label",
          backgroundColor: "transparent",
          content: centerText,
          color: colorCommonGrey800,
          xValue: 0, // either xValue or yValue is required to make the annotation visible
          font: {
            // lightTextRegular
            family: "Work Sans",
            size: 24, // fontSize8
            lineHeight: 1,
            weight: 400,
          },
        },
      }
    : undefined;
}

function buildChartOptions({ centerText }: ChartConfigOptions): ChartOptions<"doughnut"> {
  return {
    ...DefaultDoughnutOptions,
    plugins: {
      annotation: {
        annotations: buildCenterAnnotation(centerText),
      },
    },
  };
}

export const DoughnutChart = <TData extends number[], TLabel>({
  data,
  ...configOptions
}: DoughnutChartProps<TData, TLabel>) => {
  const options = useMemo(() => buildChartOptions(configOptions), [configOptions]);

  return <Doughnut className={styles.chart} data={data} options={options} />;
};
