import classNames from "classnames";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";
import { Icon } from "../Icon";
import styles from "./Select.module.scss";

type SelectOption = Omit<SelectItem, "className">;

type SelectProps<ValueType, OptionType extends SelectOption> = Omit<
  DropdownProps,
  "className" | "onSelect" | "options" | "pt" | "size" | "value"
> & {
  testId?: string;
  label?: string;
  value?: ValueType;
  options?: OptionType[];
  onSelect: (value: ValueType) => void;
  invalid?: boolean;
  size?: "normal" | "small";
};

export const Select = <ValueType, OptionType extends SelectOption>({
  testId,
  label,
  value,
  options = [],
  onSelect,
  invalid,
  name,
  size = "normal",
  ...dropdownProps
}: SelectProps<ValueType, OptionType>) => {
  const defaultProps: DropdownProps = {
    itemTemplate: (option: OptionType) => (
      <div className={styles.dropdownItemContent}>
        {option.label}
        {value === option.value && (
          <Icon testId={`${testId}-selectedIcon`} icon="check" className={styles.selectedIcon} />
        )}
      </div>
    ),
    filter: true,
    filterInputAutoFocus: true,
    resetFilterOnHide: true,
    showOnFocus: true,
    pt: {
      root: (options) => ({
        className: classNames(
          styles.dropdownRoot,
          styles.inputWrapper,
          /* c8 ignore next 1 -- options being undefined is not applicable here */
          /* istanbul ignore next */
          options?.props.disabled ? styles.disabled : undefined,
        ),
      }),
      input: () => ({
        className: styles.input,
      }),
      filterInput: () => ({
        className: classNames(styles.inputWrapper, styles.input),
      }),
      filterIcon: () => ({
        className: styles.filterIcon,
      }),
      header: () => ({
        className: styles.dropdownHeader,
      }),
      wrapper: () => ({
        className: styles.dropdownWrapper,
      }),
      item: () => ({
        className: styles.dropdownItem,
      }),
      list: () => ({
        className: styles.itemList,
      }),
    },
  };

  return (
    <>
      <label htmlFor={name} className={styles.label}>
        {label}
        <Dropdown
          {...defaultProps}
          data-testid={testId}
          value={value}
          name={name}
          options={options}
          onKeyDown={(event) => {
            /* istanbul ignore next */
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
              document.querySelector("[data-p-focused=true]")?.scrollIntoView(true);
            }
          }}
          onChange={(event) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- type `any` comes from primereact with no way to fix it
            return onSelect(event.value);
          }}
          className={classNames(invalid ? styles.invalid : undefined, styles[size])}
          panelClassName={styles.dropdown}
          {...dropdownProps}
        />
      </label>
    </>
  );
};
