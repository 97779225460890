import { useTranslation } from "@simplicate/translations";
import { Tab, TabBar } from "@simplicate/ui";
import { IFrameActionButtons } from "../../IFrameActionButtons";

export const DebtorsTabs = () => {
  const { t } = useTranslation("tab_bar");

  return (
    <TabBar testId="debtors" actions={<IFrameActionButtons />}>
      <Tab href="/crm/organizations" title={t("organizations")} />
      <Tab href="/crm/persons" title={t("persons")} />
      <Tab href="/crm/debtors" title={t("debtors")} />
    </TabBar>
  );
};
