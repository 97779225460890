import { List, Widget } from "@simplicate/ui";
import { MultiKPIRow, MultiKPIRowProps } from "./MultiKPIRow";
import styles from "./MultiKPIWidget.module.scss";

type MultiKPIWidgetProps = {
  title: string;
  kpis: readonly MultiKPIRowProps[];
};

export const MultiKPIWidget = ({ title, kpis }: MultiKPIWidgetProps) => {
  return (
    <Widget title={title}>
      <div className={styles.widgetBody}>
        <List>
          {kpis.map((kpi) => (
            <MultiKPIRow key={`${kpi.label}-${kpi.aggregation}`} {...kpi} />
          ))}
        </List>
      </div>
    </Widget>
  );
};
