import { useEnvironmentSetting } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const InvoicesApprovalMenuItem = ({ isGetStarted }: { isGetStarted: boolean }) => {
  const { t } = useTranslation("main_menu");

  const invoiceApprovalEnabled = useEnvironmentSetting("invoices", "is_use_invoice_approval")?.value === 1;

  if (isGetStarted || !invoiceApprovalEnabled) {
    return null;
  }

  return (
    <NavMenu.SubItem href="/invoicing/approval" title={t("invoicing_approval")} testId="invoices-approval-menu-item" />
  );
};
