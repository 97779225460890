import { Big, type CostTypeInService } from "@simplicate/api-client";
import { type CostTypeInForm } from "./useProjectServiceForm";

export const transformToCostTypeInForm = (costTypeFromApi: CostTypeInService): CostTypeInForm => {
  const costType = {
    identifier: costTypeFromApi.identifier,
    name: costTypeFromApi.defaultCostType.name,
    purchasePrice: {
      amount: Big(costTypeFromApi.purchasePrice.amount),
      currency: costTypeFromApi.purchasePrice.currency,
    },
    sellingPrice: {
      amount: Big(costTypeFromApi.sellingPrice.amount),
      currency: costTypeFromApi.sellingPrice.currency,
    },
    margin: costTypeFromApi.margin,
    total: {
      amount: Big(costTypeFromApi.total.amount),
      currency: "EUR" as const,
    },
  };

  if (costTypeFromApi.defaultCostType.usePricePerUnit && costTypeFromApi.defaultCostType.unit) {
    return {
      ...costType,
      usePricePerUnit: true,
      unit: costTypeFromApi.defaultCostType.unit,
      quantity: 1,
    };
  }

  return {
    ...costType,
    usePricePerUnit: false,
  };
};
