import { useTranslation } from "@simplicate/translations";
import { Button, Tag, TagList } from "@simplicate/ui";
import { useCallback } from "react";
import { useDashboardContext, LabeledFilter } from "../../components/Dashboard";
import { DimensionValueSelect } from "../../components/DimensionValueSelect";
import { CubeDimension, cubeDimensionToKey } from "../../types";
import styles from "./FilterWidget.module.scss";

export type DimensionFilterConfig = {
  dimension: CubeDimension;
  placeholder?: string;
  filterFormat?: (value: unknown) => string;
};

type FilterWidgetProps = {
  dimensions: DimensionFilterConfig[];
};

export const FilterWidget = ({ dimensions }: FilterWidgetProps) => {
  const { t } = useTranslation("insights");
  const {
    actions: { removeFilter, addFilter, clearAllFilters },
    state: { filters },
  } = useDashboardContext();

  const handleSelectDimensionValue = useCallback(
    (filterLabel: string, value: string, dimension: CubeDimension) => {
      addFilter(filterLabel, {
        member: cubeDimensionToKey(dimension),
        operator: "equals",
        values: [value],
      });
    },
    [addFilter],
  );

  return (
    <div className={styles.filterWidgetContainer}>
      <div className={styles.dropdownsRow}>
        {dimensions.map(({ dimension, placeholder, filterFormat }) => (
          <DimensionValueSelect
            key={cubeDimensionToKey(dimension)}
            placeholder={placeholder}
            dimension={dimension}
            onChange={(value) => {
              const valueAsLabel = filterFormat ? filterFormat(value) : value;

              handleSelectDimensionValue(valueAsLabel, value, dimension);
            }}
          />
        ))}
      </div>
      <TagList>
        <Tag text={t("filters.this_month")} />
        {filters.map(({ label }: LabeledFilter) => {
          return <Tag key={label} text={label} onClose={() => removeFilter(label)} />;
        })}
        <Button
          variant="subtle"
          size="small"
          iconProps={{ icon: "times" }}
          text="Reset filters"
          onClick={clearAllFilters}
        />
      </TagList>
    </div>
  );
};
