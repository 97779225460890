import { PropsWithChildren } from "react";
import styles from "./Widget.module.scss";

type WidgetProps = PropsWithChildren<{
  title: string;
}>;

export const Widget = ({ children, title }: WidgetProps) => {
  return (
    <div className={styles.widget}>
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.body}>{children}</div>
    </div>
  );
};
