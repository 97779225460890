import { useEnvironmentSetting, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const ProjectReviewMenuItem = ({ isGetStarted }: { isGetStarted: boolean }) => {
  const { t } = useTranslation("main_menu");
  const projectReviewEnabled = useEnvironmentSetting("hours", "hours_project_approval")?.value === 1;
  const hasProjectReviewPermission = usePermission("hours", "hours_approval_project").granted;

  if (isGetStarted || !hasProjectReviewPermission || !projectReviewEnabled) {
    return null;
  }

  return (
    <NavMenu.SubItem href="/hours/project" title={t("hours_project_review")} testId="hours-project-review-menu-item" />
  );
};
