import { DeeplyReadonly, Query } from "@cubejs-client/core";
import { PropsWithChildren, useReducer } from "react";
import { useDataset } from "../../data";
import { DashboardContext } from "./Dashboard.context";
import { dashboardStateReducer } from "./Dashboard.reducer";

type DashboardProps = PropsWithChildren<{
  query: DeeplyReadonly<Query>;
}>;

export const Dashboard = ({ children, query }: DashboardProps) => {
  const [state, dispatch] = useReducer(dashboardStateReducer, { filters: [], search: "" });
  const queryResult = useDataset({ ...query, state });

  return (
    <DashboardContext.Provider
      value={{
        state,
        dispatch,
        queryResult,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
