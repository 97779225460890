import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Button, Icon } from "@simplicate/ui";
import classNames from "classnames";
import styles from "./ServiceGroupHeader.module.scss";

type ServiceGroupHeaderProps = {
  text: string;
  isUngroupedContainer: boolean;
  onDuplicateGroup?: () => void;
  onAutoGroup?: () => void;
  deleteCallback?: () => void;
};

export const ServiceGroupHeader = ({
  text,
  isUngroupedContainer,
  onDuplicateGroup,
  onAutoGroup,
  deleteCallback,
}: ServiceGroupHeaderProps) => {
  const { t } = useTranslation("grouped_services_manager");

  return (
    <div className={styles.header} data-testid="service-group-header">
      <div className={styles.headerLeft}>
        <span className={classNames(styles.grip, isUngroupedContainer && styles.hidden)}>
          <Icon icon="grip" />
        </span>
        <h2 className={styles.groupName}>{text}</h2>
      </div>
      <div className={styles.headerRight}>
        {isUngroupedContainer ? (
          onAutoGroup && (
            <Button
              variant="secondary"
              iconProps={{ icon: "wand" }}
              text={t("group_by_invoice_method")}
              onClick={onAutoGroup}
            />
          )
        ) : (
          <div className={styles.buttonDropdownContainer}>
            <ActionDropdown testId="dropdown-menu" ariaLabel={t("actions")}>
              <ActionDropdown.Action label={t("duplicate")} icon="copy" onClick={onDuplicateGroup} />
              <ActionDropdown.Action
                label={t("delete")}
                icon="trash"
                variant="invisibleDanger"
                onClick={deleteCallback}
              />
            </ActionDropdown>
          </div>
        )}
      </div>
    </div>
  );
};
