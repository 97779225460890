// istanbul ignore file -- This is still a demo component
import { Query } from "@cubejs-client/core";
import { useTranslation } from "@simplicate/translations";
import { ColumnsTemplate, Page } from "@simplicate/ui";
import { spacing5 } from "@simplicate-software/design-tokens";
import { CubeProvider, Dashboard } from "../../components";
import {
  FilterWidget,
  TableWidget,
  DimensionFilterConfig,
  TableWidgetColumn,
  MultiKPIWidget,
  TargetKPIWidget,
} from "../../widgets";
import styles from "./HoursReportPage.module.scss";

const QUERY: Query = {
  timeDimensions: [
    {
      dimension: "hours_report.timetable_date",
      dateRange: "this month",
    },
  ],
  dimensions: ["hours_report.employee_name"],
  measures: [
    "hours_report.timetable_available_hours",
    "hours_report.timetable_corrected_value",
    "hours_report.timetable_declarability",
    "hours_report.timetable_declarable_value",
    "hours_report.timetable_productivity",
    "hours_report.timetable_raster_hours",
    "hours_report.timetable_registered_hours",
    "hours_report.timetable_registered_value",
  ],
  order: {
    "hours_report.employee_name": "asc",
  },
};

const AVAILABLE_HOURS_COLUMNS = ["hours_report", "timetable_available_hours"] as const;
const CORRECTED_VALUE_COLUMN = ["hours_report", "timetable_corrected_value"] as const;
const DECLARABILITY_COLUMN = ["hours_report", "timetable_declarability"] as const;
const DECLARABLE_VALUE_COLUMN = ["hours_report", "timetable_declarable_value"] as const;
const EMPLOYEE_NAME_COLUMN = ["hours_report", "employee_name"] as const;
const SUPERVISOR_NAME_COLUMN = ["hours_report", "supervisor_name"] as const;
const TEAM_NAME_COLUMN = ["hours_report", "team_name"] as const;
const PRODUCTIVITY_COLUMN = ["hours_report", "timetable_productivity"] as const;
const RASTER_HOURS_COLUMN = ["hours_report", "timetable_raster_hours"] as const;
const REGISTERED_HOURS_COLUMN = ["hours_report", "timetable_registered_hours"] as const;
const REGISTERED_VALUE_COLUMN = ["hours_report", "timetable_registered_value"] as const;

const FILTER_CONFIG: DimensionFilterConfig[] = [
  {
    dimension: EMPLOYEE_NAME_COLUMN,
    placeholder: "Employee",
    filterFormat: (value: unknown) => `Employee: ${value as string}`,
  },
  {
    dimension: TEAM_NAME_COLUMN,
    placeholder: "Team",
    filterFormat: (value: unknown) => `Team: ${value as string}`,
  },
  {
    dimension: SUPERVISOR_NAME_COLUMN,
    placeholder: "Supervisor",
    filterFormat: (value: unknown) => `Supervisor: ${value as string}`,
  },
];

const LEFT_KPI_CONFIG = [
  { dimension: RASTER_HOURS_COLUMN, label: "Timetable hours", aggregation: "sum" },
  { dimension: REGISTERED_HOURS_COLUMN, label: "Registered", aggregation: "sum" },
  { dimension: AVAILABLE_HOURS_COLUMNS, label: "Availibility", aggregation: "sum" },
] as const;

const RIGHT_KPI_CONFIG = [
  { dimension: REGISTERED_VALUE_COLUMN, label: "Registered", aggregation: "sum" },
  { dimension: CORRECTED_VALUE_COLUMN, label: "Corrections", aggregation: "sum" },
  { dimension: DECLARABLE_VALUE_COLUMN, label: "Billability", aggregation: "sum" },
] as const;

const COLUMNS_CONFIG: TableWidgetColumn[] = [
  {
    dimension: EMPLOYEE_NAME_COLUMN,
    header: "Employee",
    sortable: true,
    width: "dynamic",
  },
  {
    dimension: RASTER_HOURS_COLUMN,
    header: "Timetable Hours",
    sortable: true,
    isNumeric: true,
    width: "dynamic",
  },
  {
    dimension: REGISTERED_HOURS_COLUMN,
    header: "Registered",
    sortable: true,
    isNumeric: true,
    width: "dynamic",
  },
  {
    dimension: AVAILABLE_HOURS_COLUMNS,
    header: "Available",
    sortable: true,
    isNumeric: true,
    width: "dynamic",
  },
  {
    dimension: PRODUCTIVITY_COLUMN,
    header: "Productivity",
    sortable: true,
    isNumeric: true,
    width: "dynamic",
  },
  {
    dimension: DECLARABILITY_COLUMN,
    header: "Billability",
    sortable: true,
    isNumeric: true,
    width: "dynamic",
  },
  {
    dimension: DECLARABLE_VALUE_COLUMN,
    header: "Billable",
    sortable: true,
    isNumeric: true,
    width: "dynamic",
  },
];

// TODO add testcases before leaving POC state
export const HoursReportPage = () => {
  const { t } = useTranslation("insights");

  return (
    <Page>
      <CubeProvider>
        <Dashboard query={QUERY}>
          <ColumnsTemplate>
            <ColumnsTemplate.Column columnSpan={12}>
              <h2>{t("hours.report_title")}</h2>
            </ColumnsTemplate.Column>
          </ColumnsTemplate>
          <div style={{ width: "100%", marginBlock: spacing5 }}>
            <FilterWidget dimensions={FILTER_CONFIG} />
          </div>
          <div className={styles.kpiContainer}>
            <MultiKPIWidget title="Hours" kpis={LEFT_KPI_CONFIG} />
            <TargetKPIWidget title="Productivity" dimension={PRODUCTIVITY_COLUMN} aggregation="average" />
            <TargetKPIWidget title="Billability" dimension={DECLARABILITY_COLUMN} aggregation="average" />
            <MultiKPIWidget title="Billable" kpis={RIGHT_KPI_CONFIG} />
          </div>
          <div style={{ width: "100%", marginBlock: spacing5 }}>
            <TableWidget title="Employees" columns={COLUMNS_CONFIG} />
          </div>
        </Dashboard>
      </CubeProvider>
    </Page>
  );
};
