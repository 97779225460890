import classnames from "classnames";
import { PropsWithChildren } from "react";
import { Icon, IconName } from "../Icon";
import styles from "./Notification.module.scss";

export type NotificationType = "error" | "info" | "success" | "warning";
export type NotificationProps = PropsWithChildren<{
  closeToast?: () => void;
  dismissible?: boolean;
  message?: string;
  /**
   * Type of the notification.
   * Determines the accent color of the notification and which icon is used.
   * When left empty no accent color is applied, nor will it have an icon.
   */
  type?: NotificationType | "default";
  variant?: "inline" | "toast";
  width?: "narrow" | "wide";
}>;

const ICON_MAP: Record<NotificationType, IconName> = {
  error: "exclamationTriangle",
  info: "infoCircle",
  success: "check",
  warning: "exclamationCircle",
};

export const Notification = ({
  closeToast,
  dismissible,
  message,
  children,
  type = "default",
  variant = "inline",
  width = "narrow",
}: NotificationProps) => {
  return (
    <div className={classnames(styles.notification, styles[type], styles[variant], styles[width])}>
      {type !== "default" && (
        <span className={styles.icon}>
          <Icon fixedWidth icon={ICON_MAP[type]} />
        </span>
      )}
      {message && <span className={classnames(styles.content, styles.message)}>{message}</span>}
      {children && <div className={styles.content}>{children}</div>}
      {dismissible && closeToast && (
        <button className={styles.closeButton} onClick={closeToast}>
          <span>
            <Icon icon="times" />
          </span>
        </button>
      )}
    </div>
  );
};
