import { useTranslation } from "@simplicate/translations";
import { Input } from "@simplicate/ui";
import { memo } from "react";

type DescriptionTextInputProps = {
  value: string | undefined;
  error: string | undefined;
  touched?: boolean;
  onChange: (value: string) => void;
  disabled: boolean;
};

export const DescriptionTextInput = memo(function DescriptionTextInput({
  value,
  error,
  onChange,
  disabled,
  touched = false,
}: DescriptionTextInputProps) {
  const { t } = useTranslation("project_services");

  return (
    <div style={{ gridRow: 2 }}>
      <Input
        type="text"
        name="description"
        testId="description-text-input"
        label={t("description")}
        value={value}
        onChange={(event) => void onChange(event.target.value)}
        hasError={touched && error !== undefined}
        disabled={disabled}
      />
    </div>
  );
});
