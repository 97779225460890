import { useGetEmployeeQuery } from "@simplicate/api-client";
import { PageHeader } from "@simplicate/top-bar";
import { useTranslation } from "@simplicate/translations";
import { Tag } from "@simplicate/ui";
import { useParams } from "react-router-dom";

export const EmployeeSubHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("top_bar");

  const { data: employee } = useGetEmployeeQuery(id as string);

  if (!employee) {
    return null;
  }

  const { name, employment_status } = employee.data;

  const employeeStatus = employment_status === "active" ? t("active-employee") : t("inactive-employee");

  return (
    <PageHeader title={name}>{employeeStatus && <Tag text={employeeStatus} size="small" isElevated />}</PageHeader>
  );
};
