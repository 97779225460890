import { useLicenseFeature, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";
import { InvoicesAccountingMenuItem } from "./InvoicesAccountingMenuItem";
import { InvoicesApprovalMenuItem } from "./InvoicesApprovalMenuItem";
import { InvoicesReportingMenuItem } from "./InvoicesReportingMenuItem";
import { ReadyForInvoicingMenuItem } from "./ReadyForInvoicingMenuItem";

export const Invoices = () => {
  const { t } = useTranslation("main_menu");
  const isGetStarted = useLicenseFeature("get_started").enabled;
  const hasViewPermission = usePermission("invoices", "view").granted;
  const themeClass = asModuleTheme("invoicing");

  if (!hasViewPermission) return null;

  return (
    <NavMenu.Item
      title={t("invoicing")}
      href="/invoicing"
      className={themeClass}
      icon="invoicing"
      testId="invoices-menu-item"
    >
      <ReadyForInvoicingMenuItem />
      <InvoicesApprovalMenuItem isGetStarted={isGetStarted} />
      <NavMenu.SubItem
        href="/invoicing/draft-invoices"
        title={t("invoicing_drafts")}
        testId="invoices-drafts-menu-item"
      />
      <NavMenu.SubItem
        href="/invoicing/outstanding"
        title={t("invoicing_outstanding")}
        testId="invoices-outstanding-menu-item"
      />
      <NavMenu.SubItem href="/invoicing/all" title={t("invoicing_all")} testId="invoices-all-menu-item" />
      <InvoicesAccountingMenuItem isGetStarted={isGetStarted} />
      <InvoicesReportingMenuItem isGetStarted={isGetStarted} />
    </NavMenu.Item>
  );
};
