import { useMemo } from "react";
import { LicenseFeature, useGetLicenseQuery } from "../../endpoints/License";

export const useLicenseFeature = (license: LicenseFeature): { enabled: boolean; isLoading: boolean } => {
  const { data, isLoading } = useGetLicenseQuery();

  const enabled = useMemo(() => {
    if (!data?.licention) {
      return false;
    }

    return data.licention[license]?.value === "1";
  }, [data, license]);

  return { enabled, isLoading };
};
