import { useEnvironmentSetting } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const HoursPlanningMenuItem = ({ isGetStarted }: { isGetStarted: boolean }) => {
  const { t } = useTranslation("main_menu");

  // TODO: Remove this setting guard. See https://simplicate.atlassian.net/browse/SIM-35562
  const projectsModuleEnabled = useEnvironmentSetting("environment", "active_modules")?.value?.includes("projects");

  if (isGetStarted || !projectsModuleEnabled) {
    return null;
  }

  return <NavMenu.SubItem href="/hours/planning" title={t("hours_planning")} testId="hours-planning-menu-item" />;
};
