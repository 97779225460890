import classnames from "classnames";
import React from "react";
import { getContrastColor } from "../../utils/getContrastColor";
import { ModuleIcon } from "../ModuleIcon";
import styles from "./Avatar.module.scss";

type AvatarProps = {
  src?: string;
  altText?: string;
  size: "large" | "medium" | "mega" | "small";
  testId?: string;
  color?: string;
};

export const Avatar = ({ src, altText, size, testId, color }: AvatarProps) => {
  const [showImage, setShowImage] = React.useState(true);
  const textColor = getContrastColor(color);

  return (
    <div
      className={classnames(styles.avatarWrapper, styles[size])}
      style={{ backgroundColor: color }}
      data-testid={testId}
    >
      {src && showImage ? (
        <img src={src} alt={altText} className={styles.avatar} onError={() => setShowImage(false)} />
      ) : altText ? (
        <span style={{ color: textColor }} className={styles.altText}>
          {altText.slice(0, 3)}
        </span>
      ) : (
        <ModuleIcon module="smile" className={styles.smileIcon} />
      )}
    </div>
  );
};
