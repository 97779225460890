import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import styles from "./TextArea.module.scss";

type TextAreaProps = Omit<InputHTMLAttributes<HTMLTextAreaElement>, "value"> & {
  name: string;
  label: string;
  testId?: string;
  hasError?: boolean;
  disabled?: boolean;
  value?: string;
};

export const TextArea = ({
  testId,
  name,
  label,
  value = "",
  hasError = false,
  disabled = false,
  ...props
}: TextAreaProps) => {
  return (
    <label
      htmlFor={name}
      className={classNames(styles.textarea, hasError && styles.hasError, disabled && styles.disabled)}
      data-testid={`inputWrapper-${testId}`}
    >
      <span className={styles.label}>{label}</span>
      <textarea
        id={name}
        name={name}
        value={value}
        className={styles.input}
        data-testid={testId}
        disabled={disabled}
        {...props}
      />
    </label>
  );
};
