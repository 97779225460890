import { useLicenseFeature } from "@simplicate/api-client";
import { useEffect } from "react";
import { Outlet, To, useNavigate } from "react-router-dom";

type RedirectToFirstAccessiblePageProps = { to?: To };

export const RedirectToFirstAccessiblePage = ({ to }: RedirectToFirstAccessiblePageProps) => {
  const { enabled: isGetStarted, isLoading } = useLicenseFeature("get_started");
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isGetStarted) {
      navigate("/crm", { replace: true });
    } else if (to) {
      navigate(to, { replace: true });
    }
  }, [isGetStarted, isLoading, navigate, to]);

  return <Outlet />;
};
