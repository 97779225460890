import { useGetLoggedInQuery } from "@simplicate/api-client";
import { showToast } from "@simplicate/ui";
import { differenceInMilliseconds, differenceInMinutes } from "date-fns";
import { PropsWithChildren, useEffect, useState } from "react";
import { setWindowLocation } from "../../routing/utils";
import { SessionExpiryNotification } from "./SessionExpiryNotification";

const POLLING_INTERVAL = 5 * 60 * 1000; // 5 minutes
const SESSION_ABOUT_TO_EXPIRE_TIME_TRHESHOLD = 15;
const LOGOUT_URL = "/site/logout";

export const LoggedInGuard = ({ children }: PropsWithChildren) => {
  const [didShowToast, setDidShowToast] = useState(false);
  const { data, isLoading, isError } = useGetLoggedInQuery(undefined, {
    pollingInterval: POLLING_INTERVAL,
  });

  useEffect(() => {
    if (isError) {
      setWindowLocation(LOGOUT_URL);
    }
  }, [isError]);

  const timeRemaining = data?.expiration_date
    ? differenceInMinutes(new Date(data.expiration_date), new Date())
    : undefined;

  useEffect(() => {
    if (!isLoading && data?.type !== "user") {
      setWindowLocation(LOGOUT_URL);
    }
  }, [data?.type, isLoading]);

  useEffect(() => {
    if (
      !didShowToast &&
      data?.expiration_date &&
      timeRemaining !== undefined &&
      timeRemaining <= SESSION_ABOUT_TO_EXPIRE_TIME_TRHESHOLD
    ) {
      showToast({
        content: <SessionExpiryNotification exprirationDate={new Date(data.expiration_date).valueOf()} />,
        type: "info",
        dismissible: false,
        duration: 0,
      });
      setDidShowToast(true);
      setTimeout(
        () => {
          setWindowLocation(LOGOUT_URL);
        },
        differenceInMilliseconds(new Date(data.expiration_date), new Date()),
      );
    }
  }, [data?.expiration_date, didShowToast, timeRemaining]);

  if (isLoading || data?.type !== "user") {
    return null;
  }

  return children;
};
