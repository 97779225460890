import { BinaryFilter } from "@cubejs-client/core";
import { UseCubeQueryResult } from "@cubejs-client/react";
import { createContext, useContext, useMemo } from "react";
import { DashboardState, ReducerAction } from "./Dashboard.reducer";

type DashboardContextType = {
  state: DashboardState;
  dispatch: (action: ReducerAction) => void;
  queryResult: UseCubeQueryResult<unknown, unknown>;
};

export const DashboardContext = createContext<DashboardContextType | null>(null);

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (context === null) {
    throw new Error("Can only use `useDashboardContext` inside `DashboardContextProvider`");
  }

  const { dispatch, state, queryResult } = context;

  const actions = useMemo(
    () => ({
      addFilter: (label: string, filter: BinaryFilter) => dispatch({ type: "ADD_FILTER", filter, label }),
      clearAllFilters: () => dispatch({ type: "CLEAR_ALL_FILTERS" }),
      orderBy: (key: string) => dispatch({ type: "ORDER_BY", key }),
      removeFilter: (label: string) => dispatch({ type: "REMOVE_FILTER", label }),
      setSearch: (search: string) => dispatch({ type: "SET_SEARCH", search }),
    }),
    [dispatch],
  );

  return { state, actions, queryResult } as const;
};
