import { Select } from "@simplicate/ui";
import { useMemo } from "react";
import { useDataset } from "../../data";
import { CubeDimension, cubeDimensionToKey } from "../../types";

type ValueType = { label: string; value: string; key: string };

export type DimensionSelectProps = {
  dimension: CubeDimension;
  placeholder?: string;
  onChange: (value: string) => void;
};

export const DimensionValueSelect = ({ dimension, placeholder, onChange }: DimensionSelectProps) => {
  const { resultSet } = useDataset({
    dimensions: [cubeDimensionToKey(dimension)],
  });

  const options = useMemo(() => {
    if (!resultSet) return [];

    return resultSet
      .tablePivot()
      .map((record): ValueType | undefined => {
        const key = cubeDimensionToKey(dimension);

        if (record[key] === undefined || record[key] === null) {
          return undefined;
        }

        const value = record[key];

        return {
          key: String(value),
          label: String(value),
          value: String(value),
        };
      })
      .filter((option): option is ValueType => option !== undefined);
  }, [dimension, resultSet]);

  const handleChange = (value: string) => {
    onChange(value);
  };

  return <Select placeholder={placeholder} options={options} onSelect={handleChange} />;
};
