import { ListItem } from "@simplicate/ui";
import { useMemo } from "react";
import { useDashboardContext } from "../../../components/Dashboard";
import { AggregatorType, useAggregator } from "../../../data";
import { CubeDimension } from "../../../types";
import styles from "./MultiKPIRow.module.scss";

export type MultiKPIRowProps = {
  dimension: CubeDimension;
  label: string;
  aggregation: AggregatorType;
  formatter?: (value: unknown) => string;
};

export const MultiKPIRow = ({ dimension, label, aggregation, formatter }: MultiKPIRowProps) => {
  const {
    queryResult: { resultSet },
  } = useDashboardContext();
  const aggregationFunction = useAggregator(dimension, aggregation);
  const value = useMemo(() => aggregationFunction(resultSet), [aggregationFunction, resultSet]);

  return (
    <ListItem className={styles.kpiRow}>
      <span>{label}</span>
      <span>{formatter ? formatter(value) : value}</span>
    </ListItem>
  );
};
