import { useEnvironmentSetting, useLicenseFeature } from "@simplicate/api-client";

export const useProjectPurchaseTabPermissions = () => {
  const hasUseEmployeePurchaseEnabled =
    useEnvironmentSetting("projects", "is_use_project_purchase_employee")?.value === 1;
  const hasUseInvoicePurchaseEnabled =
    useEnvironmentSetting("projects", "is_use_project_purchase_invoice")?.value === 1;
  const hasUseCostsPurchaseEnabled = useEnvironmentSetting("projects", "is_use_project_purchase_costs")?.value === 1;

  const hasPurchaseCostsLicense = useLicenseFeature("purchase_costs").enabled;

  return {
    showProjectPurchaseTab: hasUseEmployeePurchaseEnabled || hasUseInvoicePurchaseEnabled || hasUseCostsPurchaseEnabled,
    disableProjectPurchaseTab: !hasPurchaseCostsLicense,
  };
};
