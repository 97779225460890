import { spacing3 } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { Icon } from "../../Icon";
import { useMenuContext } from "../NavMenu.context";
import styles from "./ExpandButton.module.scss";

type ExpandButtonProps = {
  testId?: string;
};

export const ExpandButton = ({ testId }: ExpandButtonProps) => {
  const { expanded, toggleExpand, expandMenuLabel, collapseMenuLabel } = useMenuContext();

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control -- button element is interactive
    <label className={classNames(styles.verticalSeparator, expanded && styles.expanded)}>
      <button
        className={styles.expandbutton}
        onClick={toggleExpand}
        title={expanded ? collapseMenuLabel : expandMenuLabel}
        data-testid={testId}
      >
        <span>
          <Icon icon={expanded ? "chevronLeft" : "chevronRight"} width={spacing3} />
        </span>
        <span className={styles.keyboardFocusOnly}>{expanded ? collapseMenuLabel : expandMenuLabel}</span>
      </button>
    </label>
  );
};
