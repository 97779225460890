import { useGetV1IdentifierQuery } from "@simplicate/api-client";
import { useMemo } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

function withSearchParams(location: string, searchParams: URLSearchParams) {
  const searchQuery = searchParams.toString();

  if (searchQuery.length > 0) {
    const parsedLocation = new URL(location, window.origin);
    const combinedSearchParams = new URLSearchParams([...searchParams, ...parsedLocation.searchParams]);

    return `${parsedLocation.pathname}?${combinedSearchParams.toString()}${parsedLocation.hash}`;
  }

  return location;
}

export const useLocationCorrection = (location: string) => {
  const topLevelLocation = useLocation();
  const [searchParams] = useSearchParams();
  const { id: v2Identifier } = useParams<{ id: string }>();
  const identifierRequiresConversion = location.includes(":id");
  const { data: v1Identifier, isFetching } = useGetV1IdentifierQuery(v2Identifier, {
    skip: !identifierRequiresConversion,
  });

  return useMemo(() => {
    if (identifierRequiresConversion) {
      if (isFetching) {
        return {
          isLoading: true,
          location: "",
        };
      }

      return {
        isLoading: false,
        location:
          withSearchParams(location.replace(":id", /* istanbul ignore next */ v1Identifier ?? ""), searchParams) +
          topLevelLocation.hash,
      };
    }

    return {
      isLoading: false,
      location: withSearchParams(location, searchParams) + topLevelLocation.hash,
    };
  }, [identifierRequiresConversion, isFetching, location, searchParams, topLevelLocation.hash, v1Identifier]);
};
