import { skipToken } from "@reduxjs/toolkit/query/react";
import {
  useEnvironmentSetting,
  useGetCurrentUserQuery,
  useGetOrganizationQuery,
  useLicenseFeature,
  usePermission,
} from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { IFrameActionButtons } from "../../IFrameActionButtons";

const MY_ORGANIZATION_RELATION_TYPE_ID = "relationtype:56fb2ce4722a7509515efc18bba82b90";

const useViewTabPermissions = () => {
  const hasViewCrmPermission = usePermission("crm", "view").granted;
  const hasViewInvoicesPermission = usePermission("invoices", "view").granted;
  const hasViewSalesPermission = usePermission("acquisition", "view").granted;
  const hasViewProjectsPermission = usePermission("projects", "view").granted;

  return {
    hasViewCrmPermission,
    hasViewInvoicesPermission,
    hasViewSalesPermission,
    hasViewProjectsPermission,
  };
};

const useIsMyOrganization = (organizationId: string | undefined) => {
  const { data } = useGetOrganizationQuery(organizationId ?? skipToken);

  return {
    isMyOrganization: data?.data?.relation_type?.id === MY_ORGANIZATION_RELATION_TYPE_ID,
  };
};

const useIsLightUser = (skipQuery?: boolean) => {
  const { data: userData } = useGetCurrentUserQuery(skipQuery ? skipToken : undefined);

  return { isLightUser: userData?.data?.is_light_user ?? false };
};

// eslint-disable-next-line complexity -- This tab bar can have a lot of tabs, I think it's more clear if they're al here instead of separated to satisfy eslint complexity rule
export const OrganizationTabs = () => {
  const { t } = useTranslation("tab_bar");

  const { id } = useParams<{ id: string }>();

  const { hasViewCrmPermission, hasViewInvoicesPermission, hasViewProjectsPermission, hasViewSalesPermission } =
    useViewTabPermissions();
  const hasShowCrmTariffSetting = useEnvironmentSetting("crm", "showcrmtariff")?.value === 1;
  const { isLightUser } = useIsLightUser(!!id);
  const { isMyOrganization } = useIsMyOrganization(id);

  const isGetStarted = useLicenseFeature("get_started").enabled;

  if (!hasViewCrmPermission || !id) {
    return null;
  }

  return (
    <TabBar testId="organization" actions={<IFrameActionButtons />}>
      <Tab href={`/crm/organizations/${id}/overview`} title={t("overview")} testId="overview-tab" />
      <Tab
        href={`/crm/organizations/${id}/general`}
        title={t("organization-general")}
        testId="organization-general-tab"
      />
      {isMyOrganization && (
        <Tab
          href={`/crm/organizations/${id}/employees`}
          title={t("employees_at_my_organization")}
          testId="employees-at-my-organization-tab"
        />
      )}
      {!isMyOrganization && (
        <Tab href={`/crm/organizations/${id}/contacts`} title={t("contacts")} testId="contacts-tab" />
      )}
      {hasViewSalesPermission && <Tab href={`/crm/organizations/${id}/sales`} title={t("sales")} testId="sales-tab" />}
      {hasViewProjectsPermission && (
        <Tab href={`/crm/organizations/${id}/projects`} title={t("projects")} testId="projects-tab" />
      )}
      {hasViewInvoicesPermission && !isLightUser && (
        <Tab href={`/crm/organizations/${id}/invoicing`} title={t("invoices")} testId="invoices-tab" />
      )}
      {hasShowCrmTariffSetting && (
        <Tab
          disabled={isGetStarted}
          disabledText={t("not_available_in_get_started_message")}
          href={`/crm/organizations/${id}/tariff_agreements`}
          title={t("tariff_agreements")}
          testId="tariff-agreements-tab"
        />
      )}
      {!isLightUser && (
        <Tab
          disabled={isGetStarted}
          disabledText={t("not_available_in_get_started_message")}
          href={`/crm/organizations/${id}/tasks`}
          title={t("tasks")}
          testId="tasks-tab"
        />
      )}
      {!isLightUser && (
        <Tab href={`/crm/organizations/${id}/documents`} title={t("documents")} testId="documents-tab" />
      )}
    </TabBar>
  );
};
