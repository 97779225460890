import { CurrencyFormat, NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon, Tag } from "@simplicate/ui";
import { ServiceNodeModel } from "../../GroupedServicesManager.types";
import { ServiceRowCell } from "../ServiceRowCell/ServiceRowCell";
import styles from "./ServiceRow.module.scss";

type ServiceRowProps = {
  node: ServiceNodeModel;
};

export const ServiceRow = ({ node: { id, text, data } }: ServiceRowProps) => {
  const { t } = useTranslation("grouped_services_manager");

  const getInvoiceMethodLabel = (invoiceMethod: string) => {
    switch (invoiceMethod) {
      case "fixed":
        return t("invoice_method.fixed");
      case "hours":
        return t("invoice_method.hours");
      case "subscription_month":
        return t("invoice_method.subscription_month");
      case "subscription_quarter":
        return t("invoice_method.subscription_quarter");
      case "subscription_halve_year":
        return t("invoice_method.subscription_halve_year");
      case "subscription_year":
        return t("invoice_method.subscription_year");
      default:
        // istanbul ignore next - this should never happen once invoiceMethod is properly typed
        return "";
    }
  };

  if (!data) return null;

  const {
    invoiceMethod,
    hoursTotalAmount,
    hoursTotalBudget,
    purchaseTotalBudget,
    price,
    duplicateCallback,
    deleteCallback,
  } = data;

  return (
    <div className={styles.serviceRow} data-testid={`service-row-${id}`}>
      <ServiceRowCell variant="grip">
        <Icon icon="grip" />
      </ServiceRowCell>
      <ServiceRowCell variant="name">
        {text}
        {invoiceMethod && <Tag size="small" text={getInvoiceMethodLabel(invoiceMethod)} variant="light" />}
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <NumberFormat value={hoursTotalAmount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <CurrencyFormat value={hoursTotalBudget?.amount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <CurrencyFormat value={purchaseTotalBudget?.amount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <CurrencyFormat value={price?.amount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="button">
        <ActionDropdown testId="dropdown-menu" ariaLabel={t("actions")}>
          <ActionDropdown.Action label={t("duplicate")} icon="copy" onClick={duplicateCallback} />
          <ActionDropdown.Action label={t("delete")} icon="trash" variant="invisibleDanger" onClick={deleteCallback} />
        </ActionDropdown>
      </ServiceRowCell>
    </div>
  );
};
