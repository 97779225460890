import styles from "./CanRegisterHoursErrors.module.scss";

type HoursSectionTitleProps = {
  touched: {
    hourTypes: boolean | undefined;
    canRegisterHours: boolean | undefined;
  };
  errors: {
    hourTypes: string | undefined;
    canRegisterHours: string | undefined;
  };
};

export const CanRegisterHoursErrors = ({ touched, errors }: HoursSectionTitleProps) => (
  <>
    {touched.hourTypes && errors.hourTypes && <span className={styles.invalid}>{errors.hourTypes}</span>}
    {touched.canRegisterHours && errors.canRegisterHours && (
      <span className={styles.invalid}>{errors.canRegisterHours}</span>
    )}
  </>
);
