import { useLicenseFeature, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { IFrameActionButtons } from "../../IFrameActionButtons";

export const DashboardTabs = () => {
  const { t } = useTranslation("tab_bar");

  const isGetStarted = useLicenseFeature("get_started").enabled;
  const hasAllWorkflowsPermission = usePermission("dossier", "all_tasks").granted;

  if (isGetStarted) {
    return null;
  }

  return (
    <TabBar testId="dashboard" actions={<IFrameActionButtons />}>
      <Tab href="/dashboard/my-tasks" title={t("my-task")} />
      {hasAllWorkflowsPermission && <Tab href="/dashboard/all-tasks" title={t("all-tasks")} />}
    </TabBar>
  );
};
