import { useLicenseFeature, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { IFrameActionButtons } from "../../IFrameActionButtons";

export const HrmDetailsPageTabs = () => {
  const { t } = useTranslation("tab_bar");
  const { id } = useParams<{ id: string }>();

  const hasViewHrmPermission = usePermission("hrm", "view").granted;
  const hasHrmOptionsLicense = useLicenseFeature("hrm_options").enabled;
  const isGetStarted = useLicenseFeature("get_started").enabled;

  if (!id || !hasViewHrmPermission) {
    return;
  }

  return (
    <TabBar testId="hrm-details" actions={<IFrameActionButtons />}>
      <Tab href={`/hrm/${id}/overview`} title={t("overview")} />
      <Tab href={`/hrm/${id}/personal-info`} title={t("personal-info")} />
      <Tab href={`/hrm/${id}/employee`} title={t("employee")} />
      <Tab href={`/hrm/${id}/user`} title={t("user")} />
      <Tab
        href={`/hrm/${id}/contract`}
        title={t("contract")}
        disabled={!hasHrmOptionsLicense}
        disabledText={t("not_available_in_get_started_message")}
      />
      <Tab href={`/hrm/${id}/timetable`} title={t("timetable")} />
      <Tab
        href={`/hrm/${id}/leave`}
        title={t("leave")}
        disabled={!hasHrmOptionsLicense}
        disabledText={t("not_available_in_license_message")}
      />
      <Tab
        href={`/hrm/${id}/absence`}
        title={t("absence")}
        disabled={!hasHrmOptionsLicense}
        disabledText={t("not_available_in_license_message")}
      />
      <Tab
        href={`/hrm/${id}/tasks`}
        title={t("tasks")}
        disabled={isGetStarted}
        disabledText={t("not_available_in_get_started_message")}
      />
      <Tab
        href={`/hrm/${id}/documents`}
        title={t("employee-documents")}
        disabled={!hasHrmOptionsLicense}
        disabledText={t("not_available_in_license_message")}
      />
    </TabBar>
  );
};
