import { DeeplyReadonly, Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { DashboardState } from "../../../components/Dashboard/Dashboard.reducer";

type CubeQuery = DeeplyReadonly<Query> & {
  state?: DashboardState;
};

export const useDataset = ({ state, ...query }: CubeQuery) => {
  const filters =
    state?.filters && state.filters.length > 0 ? [{ or: state.filters.map(({ filter }) => filter) }] : undefined;
  const order = state?.order ? { [state.order.key]: state.order.direction } : undefined;

  return useCubeQuery({
    ...query,
    filters,
    order,
  });
};
