import { ErrorInfo, createContext, useContext } from "react";

type ErrorBoundaryContext = {
  enableErrorReporting: () => void;
  reportError: (error: Error, info?: ErrorInfo, errorName?: string) => void;
};

const context = createContext<ErrorBoundaryContext | null>(null);

export const useErrorBoundaryContext = () => {
  const contextValue = useContext(context);

  if (contextValue === null) {
    throw new Error("useErrorBoundaryContext must be used within an ErrorBoundaryProvider");
  }

  return contextValue;
};

export const ErrorBoundaryProvider = context.Provider;
