import classNames from "classnames";
import { Clickable, ClickableProps } from "../Clickable";
import { Icon, type IconName, type IconProps } from "../Icon";
import styles from "./Button.module.scss";

export type ButtonProps = ClickableProps & {
  ariaLabel?: string;
  variant?: "danger" | "invisible" | "invisibleDanger" | "primary" | "secondary" | "subtle";
  size?: "medium" | "small";
  disabled?: boolean;
  text?: string;
  iconProps?: IconProps<IconName>;
  hasLeftIcon?: boolean;
};

export const Button = ({
  ariaLabel,
  disabled = false,
  testId,
  variant = "primary",
  size = "medium",
  text,
  iconProps,
  hasLeftIcon = false,
  ...clickableProps
}: ButtonProps) => {
  return (
    <Clickable
      aria-label={ariaLabel}
      className={classNames(styles.reset, styles.pill, styles[variant], styles[size], disabled && styles.disabled)}
      disabled={disabled}
      data-testid={testId}
      {...clickableProps}
    >
      {iconProps && hasLeftIcon && <Icon {...iconProps} className={styles.pillIcon} testId={`${testId}-icon`} />}
      {text && <span className={styles.text}>{text}</span>}
      {iconProps && !hasLeftIcon && <Icon {...iconProps} className={styles.pillIcon} testId={`${testId}-icon`} />}
    </Clickable>
  );
};
