import { PropsWithChildren, useState, DOMAttributes } from "react";
import { Button } from "../Button";
import { Dropdown } from "../Dropdown";
import { IconName } from "../Icon";
import { List, ListItem } from "../List";
import styles from "./ActionDropdown.module.scss";
import { DropdownContext, useDropdownContext } from "./ActionDropdownContext";

const ActionDropdown = ({
  children,
  testId,
  ariaLabel,
}: PropsWithChildren<{ testId?: string; ariaLabel?: string }>) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const closeDropdown = () => setDropdownOpen(false);

  return (
    <DropdownContext.Provider value={{ closeDropdown }}>
      <div className={styles.actionDropdown}>
        <Button
          testId={testId && `${testId}-button`}
          iconProps={{ icon: "ellipsisVertical" }}
          variant="subtle"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          ariaLabel={ariaLabel}
        />
        <div className={styles.dropdownWrapper}>
          <Dropdown
            testId={testId && `${testId}-dropdown`}
            width="auto"
            isOpen={dropdownOpen}
            onClickOutside={closeDropdown}
            onKeyupOutside={closeDropdown}
          >
            <List>{children}</List>
          </Dropdown>
        </div>
      </div>
    </DropdownContext.Provider>
  );
};

type Variant = "invisible" | "invisibleDanger";

type ActionProps = {
  icon: IconName;
  variant?: Variant;
  label: string;
  onClick: DOMAttributes<HTMLButtonElement>["onClick"];
};

const Action = ({ icon, label, onClick, variant = "invisible" }: ActionProps) => {
  const { closeDropdown } = useDropdownContext();
  const handleClick: DOMAttributes<HTMLButtonElement>["onClick"] = (event) => {
    if (onClick) {
      onClick(event);
    }

    closeDropdown?.();
  };

  return (
    <ListItem>
      <Button testId="action-button" iconProps={{ icon }} text={label} variant={variant} onClick={handleClick} />
    </ListItem>
  );
};

ActionDropdown.Action = Action;

export { ActionDropdown };
