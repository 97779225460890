import {
  HourTypeInCreateServiceBody,
  HourTypeInService,
  Big,
  transformMoneyToMoneyFromAPI,
} from "@simplicate/api-client";
import { HourTypeInForm, ValidHourTypeInForm } from "./useProjectServiceForm";

export const transformToCreateServiceHourType = (hourTypeInForm: ValidHourTypeInForm): HourTypeInCreateServiceBody => ({
  defaultHourTypeIdentifier: hourTypeInForm.identifier,
  tariff: transformMoneyToMoneyFromAPI(hourTypeInForm.tariff),
  amountOfHours: hourTypeInForm.amount,
  isInvoicable: undefined, // TODO add input to toggle this
});

export const transformToHourTypeInForm = (hourTypeFromApi: HourTypeInService): HourTypeInForm => ({
  identifier: hourTypeFromApi.defaultHourType.identifier,
  name: hourTypeFromApi.defaultHourType.name,
  amount: hourTypeFromApi.amountOfHours,
  tariff: {
    amount: Big(hourTypeFromApi.tariff.amount),
    currency: hourTypeFromApi.tariff.currency,
  },
  total: {
    amount: Big(hourTypeFromApi.tariff.amount).mul(hourTypeFromApi.amountOfHours),
    currency: hourTypeFromApi.tariff.currency,
  },
});
