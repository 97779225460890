import classNames from "classnames";
import { PropsWithChildren, ReactElement } from "react";
import styles from "./Page.module.scss";

type PageProps = PropsWithChildren & { footer?: ReactElement };

export const Page = ({ children, footer }: PageProps) => {
  return (
    <div className={classNames(styles.pageWrapper, footer && styles.hasFooter)}>
      <section className={styles.page}>{children}</section>

      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};
