/* eslint-disable @typescript-eslint/no-unsafe-member-access -- Yup types values in condition builders as any */
import { InvoiceMethod } from "@simplicate/api-client";
import { t } from "@simplicate/translations";
import * as Yup from "yup";
import type { ProjectServiceForm } from "./useProjectServiceForm";

// TODO add validation that hourTypes tariff and amount are never undefined
export const buildValidationSchema = () =>
  Yup.object<ProjectServiceForm>({
    defaultService: Yup.string().required("error_base_service_required"),
    invoiceMethod: Yup.string().required("error_invoice_method_required"),
    description: Yup.string().max(100, "error_description_too_long").required("error_description_required"),
    explanation: Yup.string().max(500, "error_explanation_too_long").optional(),
    invoiceableFrom: Yup.date().optional(),
    timeframe: Yup.object({
      startDate: Yup.date().optional(),
      endDate: Yup.date()
        .optional()
        .when(["startDate"], ([startDate], schema) => {
          if (!startDate) {
            return schema;
          }

          return schema.min(startDate, "error_end_date_before_start_date");
        }),
    }),
    revenueGroup: Yup.string().required("error_revenue_group_required"),
    vatCode: Yup.string().required("error_vat_code_required"),
    canRegisterHours: Yup.boolean().when("invoiceMethod", ([invoiceMethod], schema) => {
      // TODO add canRegisterCost condition
      //   If canRegisterCost && invoiceMethod === InvoiceMethod.time_and_expenses then this is not required
      if (invoiceMethod === InvoiceMethod.time_and_expenses) {
        return schema.isTrue(t("error_can_register_hours_must_be_enabled", { ns: "project_services" }));
      }

      return schema;
    }),
    hourTypes: Yup.array().when(["canRegisterHours", "invoiceMethod"], ([canRegisterHours, invoiceMethod], schema) => {
      // TODO add canRegisterCost condition
      //   If canRegisterCost && invoiceMethod === InvoiceMethod.time_and_expenses && costTypes.length > 0, then this is not required

      if (canRegisterHours && invoiceMethod === InvoiceMethod.time_and_expenses) {
        return schema.min(1, t("error_add_at_least_one_hour_type", { ns: "project_services" }));
      }

      return schema;
    }),
  });
