import { simplicateApiV3 } from "../components";
import { createTagTypes } from "../tags";
import { Money, MoneyFromAPI, transformMoneyFromAPIToMoney } from "./utils";

export type HourTypeFromApi = {
  identifier: string;
  name: string;
  tariff: MoneyFromAPI;
  isBlocked: boolean;
};

export type HourType = Omit<HourTypeFromApi, "tariff"> & {
  tariff: Money;
};

const tags = createTagTypes({
  tagPrefix: "HourType",
  tags: ["list", "entry"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getHourType: builder.query<HourType, string>({
      query: (identifier) => ({
        url: `/serviceConfiguration.hourType.get/${identifier}`,
      }),
      transformResponse: (response: HourTypeFromApi) => ({
        ...response,
        tariff: transformMoneyFromAPIToMoney(response.tariff),
      }),
      providesTags: (_, __, arg) => [{ type: tags.entry, id: arg }],
    }),
    getHourTypes: builder.query<HourType[], void>({
      query: () => ({
        url: "/serviceConfiguration.hourType.list",
      }),
      providesTags: [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetHourTypeQuery, useGetHourTypesQuery } = endpoints;
