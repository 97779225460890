import { useAppSelector } from "@simplicate/state";
import { APIOptions, PrimeReactProvider as PRProvider, addLocale } from "primereact/api";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { locales } from "./locales";
import "./customTheme.module.scss";

export const PrimeReactProvider = ({ children }: PropsWithChildren) => {
  const { locale } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.locale);

  useEffect(() => {
    Object.entries(locales).forEach(([localeName, locale]) => {
      addLocale(localeName, locale);
    });
  }, []);

  const context: Partial<APIOptions> = useMemo(() => ({ locale }), [locale]);

  return <PRProvider value={context}>{children}</PRProvider>;
};
