import { simplicateApiV1 } from "../components";
import { createTagTypes } from "../tags";

const INTERNAL_TO_EXTERNAL_CONVERSION_URLS = {
  employee: "/v1/hrm/employee/externalid?id=",
  invoicing: "/v1/invoices/invoice/externalid?id=",
  opportunity: "/v1/acquisition/opportunity/externalid?id=",
  organization: "/v1/crm/organization/externalid?id=",
  person: "/v1/crm/person/externalid?id=",
  project: "/v1/projects/project/externalid?id=",
  proposition: "/v1/invoices/proposition/externalid?id=",
  purchase: "/v1/projects/purchase/externalid?id=",
  reversebilling: "/v1/projects/reversebilling/externalid?id=",
} as const;

const EXTERNAL_TO_INTERNAL_CONVERSION_URLS = {
  employee: "/v1/hrm/employee/internalid?id=",
  invoice: "/v1/invoices/invoice/internalid?id=",
  organization: "/v1/crm/organization/internalid?id=",
  person: "/v1/crm/person/internalid?id=",
  project: "/v1/projects/project/internalid?id=",
  proposition: "/v1/invoices/proposition/internalid?id=",
  purchase: "/v1/projects/purchase/internalid?id=",
  reverseinvoice: "/v1/projects/reversebilling/internalid?id=",
  sales: "/v1/acquisition/opportunity/internalid?id=",
} as const;

export type V1EntityName = keyof typeof INTERNAL_TO_EXTERNAL_CONVERSION_URLS;
export type V1ToV2IdentifierQueryArgs = {
  v1Identifier: string;
  entityName: V1EntityName;
};

function determineEntityName(v2identifier: string) {
  const entityName = v2identifier.split(":")[0];

  if (entityName && entityName in EXTERNAL_TO_INTERNAL_CONVERSION_URLS) {
    return entityName as keyof typeof EXTERNAL_TO_INTERNAL_CONVERSION_URLS;
  }

  throw new Error(`Failed to determine entity of provided identifier: ${v2identifier}`);
}

const tags = createTagTypes({ tagPrefix: "IdentifierConversion", tags: ["v1identifier", "v2identifier"] });

const endpoints = simplicateApiV1.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getV2Identifier: builder.query({
      query: ({ entityName, v1Identifier }: V1ToV2IdentifierQueryArgs) =>
        `${INTERNAL_TO_EXTERNAL_CONVERSION_URLS[entityName]}${v1Identifier}`,
      transformResponse: (response: { data: { id: string } }) => response.data.id,
      keepUnusedDataFor: 60 * 60 * 24,
      providesTags: (_result, _error, queryParams) => [
        { type: tags.v2identifier, id: `${queryParams.entityName}-${queryParams.v1Identifier}` },
      ],
    }),
    getV1Identifier: builder.query({
      query: (v2identifier: string | undefined) => {
        if (!v2identifier) {
          throw new Error("No identifier provided");
        }

        return `${EXTERNAL_TO_INTERNAL_CONVERSION_URLS[determineEntityName(v2identifier)]}${v2identifier}`;
      },
      transformResponse: (response: { data: { id: string } }) => response.data.id,
      keepUnusedDataFor: 60 * 60 * 24,
      providesTags: (_result, _error, v2identifier) =>
        v2identifier ? [{ type: tags.v1identifier, id: v2identifier }] : [],
    }),
  }),
});

export const { useLazyGetV2IdentifierQuery, useGetV1IdentifierQuery } = endpoints;
