import { useTranslation } from "@simplicate/translations";
import { ActionDropdown } from "@simplicate/ui";
import { memo } from "react";

type HourTypeActionDropdownProps = {
  identifier: string;
  onClickMoveToTop: (identifier: string) => void;
  onClickMoveToBottom: (identifier: string) => void;
  onClickRemove: (identifier: string) => void;
};

export const HourTypeActionDropdown = memo(function HourTypeActionDropdown({
  identifier,
  onClickMoveToTop,
  onClickMoveToBottom,
  onClickRemove,
}: HourTypeActionDropdownProps) {
  const { t } = useTranslation("project_services");

  return (
    <ActionDropdown testId="hour-type-actions">
      <ActionDropdown.Action icon="arrowUp" label={t("move_to_top")} onClick={() => onClickMoveToTop(identifier)} />
      <ActionDropdown.Action
        icon="arrowDown"
        label={t("move_to_bottom")}
        onClick={() => onClickMoveToBottom(identifier)}
      />
      <ActionDropdown.Action
        variant="invisibleDanger"
        icon="trash"
        label={t("remove_from_grid")}
        onClick={() => onClickRemove(identifier)}
      />
    </ActionDropdown>
  );
});
