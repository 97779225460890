import { useGetDefaultServicesQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select } from "@simplicate/ui";
import { memo } from "react";

type DefaultServiceSelectProps = {
  value: string | undefined;
  touched?: boolean;
  error: string | undefined;
  onSelect: (id: string) => void;
};

export const DefaultServiceSelect = memo(function DefaultServiceSelect({
  value,
  onSelect,
  error,
  touched = false,
}: DefaultServiceSelectProps) {
  const { t } = useTranslation("project_services");

  const { data: options } = useGetDefaultServicesQuery();

  const compatibleOptions = options?.map((option) => ({
    label: option.description,
    value: option.identifier,
  }));

  return (
    <Select
      testId="base_service_select"
      name="baseService"
      label={t("base_service")}
      placeholder={t("base_service_placeholder")}
      emptyFilterMessage={t("no_base_services_found")}
      emptyMessage={t("no_base_services")}
      value={value}
      options={compatibleOptions}
      onSelect={onSelect}
      invalid={touched && error !== undefined}
    />
  );
});
