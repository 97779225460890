import { usePermission, useLicenseFeature } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";
import { ProjectPurchasingMenuItem } from "./ProjectPurchasingMenuItem";
import { ProjectsGetStarted } from "./ProjectsGetStarted";
import { ProjectsReportingMenuItem } from "./ProjectsReportingMenuItem";
import { ReverseBillingMenuItem } from "./ReverseBillingMenuItem";

export const Projects = () => {
  const { t } = useTranslation("main_menu");
  const isGetStarted = useLicenseFeature("get_started").enabled;
  const hasViewPermission = usePermission("projects", "view").granted;
  const themeClass = asModuleTheme("projects");

  if (!hasViewPermission) return null;

  return (
    <NavMenu.Item
      title={t("projects")}
      href="/projects"
      className={themeClass}
      icon="projects"
      testId="projects-menu-item"
    >
      <NavMenu.SubItem href="/projects/active" title={t("projects_active")} testId="projects-active-menu-item" />
      <NavMenu.SubItem href="/projects/closed" title={t("projects_closed")} testId="projects-closed-menu-item" />
      <NavMenu.SubItem href="/projects/all" title={t("projects_all")} testId="projects-all-menu-item" />
      {isGetStarted ? (
        <ProjectsGetStarted />
      ) : (
        <>
          <ProjectPurchasingMenuItem />
          <ReverseBillingMenuItem />
          <ProjectsReportingMenuItem />
        </>
      )}
    </NavMenu.Item>
  );
};
