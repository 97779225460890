import { useTranslation } from "@simplicate/translations";
import { buildColumnProps, Button, Table } from "@simplicate/ui";
import { size5, size6, size7, size8, size9, size10 } from "@simplicate-software/design-tokens";
import { type CostTypeInForm } from "../../useProjectServiceForm";
import styles from "./CostTypeGrid.module.scss";

type CostTypeGridProps = {
  value?: CostTypeInForm[];
};

export const CostTypeGrid = ({ value = [] }: CostTypeGridProps) => {
  const { t } = useTranslation("project_services");

  return (
    <Table testId="cost-type-grid" value={value}>
      <Table.Column
        {...buildColumnProps({
          field: "name",
          header: t("cost_type_label_header"),
          width: "dynamic",
          footer: (
            <Button
              testId="add-new-cost-type"
              text={t("add_new_cost_type_button")}
              variant="secondary"
              onClick={/* istanbul ignore next -- not yet implemented */ () => console.log("Add new cost type")}
              iconProps={{ icon: "plus" }}
            />
          ),
        })}
      />
      <Table.Column
        {...buildColumnProps({
          field: "quantity",
          header: t("cost_type_quantity_header"),
          align: "right",
          width: size6,
        })}
      />
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          field: "purchasePrice",
          header: t("cost_type_purchase_price_header"),
          align: "right",
          body: ({ purchasePrice }) => <span>{purchasePrice?.amount.toString()}</span>,
          width: size10,
          footer: (
            <div className={styles.budgetFooter}>
              <span>{t("cost_type_budget_footer_label")}</span>
              <span></span>
            </div>
          ),
        })}
      />
      <Table.Column
        {...buildColumnProps({
          field: "margin",
          header: t("cost_type_margin_header"),
          align: "right",
          width: size7,
        })}
      />
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          field: "sellingPrice",
          header: t("cost_type_selling_price_header"),
          align: "right",
          body: ({ sellingPrice }) => <span>{sellingPrice?.amount.toString()}</span>,
          width: size9,
          footer: <span>{t("cost_type_budget_total_label")}</span>,
        })}
      />
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          field: "total",
          header: t("cost_type_total_header"),
          align: "right",
          body: ({ total }) => <span>{total.amount.toString()}</span>,
          width: size8,
          footer: <span></span>,
        })}
      />
      <Table.Column {...buildColumnProps({ body: () => <span>Acties</span>, width: size5 })} />
    </Table>
  );
};
