import { ReactNode } from "react";
import { toast as toastify, type ToastOptions } from "react-toastify";
import { Notification, type NotificationProps } from "./Notification";

export type Toast = Pick<NotificationProps, "type" | "width"> & {
  message?: string;
  content?: ReactNode;
  options?: Omit<ToastOptions, "autoClose" | "draggable">;
  /**
   * Duration in milliseconds for this toast to be visible.
   * If set to `0` or `false`, the toast will be visible until the user dismisses it.
   * Defaults to 10 seconds (`10_000 ms`)
   *
   * @note
   * When the duration is set to `0` and `dismissible` is set to `false`, the toast will be permanently visible with no way to dismiss it.
   */
  duration?: number | false;
  /**
   * Whether the toast can be dismissed by the user.
   * Defaults to `true`.
   *
   * @note
   * When the duration is set to `0` and `dismissible` is set to `false`, the toast will be permanently visible with no way to dismiss it.
   */
  dismissible?: boolean;
};

export function showToast({ dismissible = true, content, options, ...toast }: Toast) {
  const autoClose = toast.duration === 0 ? false : toast.duration;

  if (content) {
    toastify(
      <Notification {...toast} dismissible={dismissible} variant="toast">
        {content}
      </Notification>,
      {
        ...options,
        autoClose,
        draggable: dismissible,
      },
    );
  } else {
    toastify(<Notification {...toast} dismissible={dismissible} variant="toast" />, {
      ...options,
      autoClose,
      draggable: dismissible,
    });
  }
}
