import { useGetJournalizeDetailsQuery, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const InvoicesAccountingMenuItem = ({ isGetStarted }: { isGetStarted: boolean }) => {
  const { t } = useTranslation("main_menu");
  const hasAccountingPermission = usePermission("invoices", "accountancy_apps").granted;
  const { data } = useGetJournalizeDetailsQuery();

  const journalizingEnabled = data?.data.active ?? false;

  if (isGetStarted || !hasAccountingPermission || !journalizingEnabled) {
    return null;
  }

  return (
    <NavMenu.SubItem
      href="/invoicing/accounting"
      title={t("invoicing_accounting")}
      testId="invoices-accounting-menu-item"
    />
  );
};
