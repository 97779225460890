import { useGetCurrentUserQuery, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { IFrameActionButtons } from "../../IFrameActionButtons";

export const HrmTabs = () => {
  const { t } = useTranslation("tab_bar");
  const { data: userData } = useGetCurrentUserQuery();

  const isLightUser = userData?.data?.is_light_user ?? false;
  const hasViewAllEmployeesPermission = usePermission("hrm", "viewall").granted;

  const myHrm = `/hrm/${userData?.data?.employee_id}`;

  return (
    <TabBar testId="hrm" actions={<IFrameActionButtons />}>
      <Tab href="/hrm/employees" title={t("employees")} />
      {!isLightUser && hasViewAllEmployeesPermission && <Tab href="/hrm/all-employees" title={t("all-employees")} />}
      {!isLightUser && <Tab href={myHrm} title={t("my-hrm")} />}
    </TabBar>
  );
};
