import { useTranslation } from "@simplicate/translations";
import { Page } from "@simplicate/ui";
import { NavCard } from "../../components/NavCard/NavCard";
import styles from "./IndexPage.module.scss";

// istanbul ignore next -- TODO: test this page once it has some logic to test
export const IndexPage = () => {
  const { t } = useTranslation("insights");
  // TODO: Fetch available dashboards from the API

  return (
    <Page>
      <h2 className={styles.indexPageTitle}>{t("index_page_title")}</h2>
      <div className={styles.indexPageLayout}>
        <NavCard
          dashboardId="hours-reporting"
          title={t("sales_funnel.report_title")}
          module="sales"
          description={t("sales_funnel.report_description")}
        />
        <NavCard
          dashboardId="hours-reporting"
          title={t("hours.report_title")}
          module="hours"
          description={t("hours.report_description")}
        />
        <NavCard
          dashboardId="hours-reporting"
          title={t("leave.report_title")}
          icon="calendarAlt"
          module="hrm"
          description={t("leave.report_description")}
        />
        <NavCard
          dashboardId="hours-reporting"
          title={t("absence.report_title")}
          module="hrm"
          description={t("absence.report_description")}
        />
      </div>
    </Page>
  );
};
