import { Table, Widget, buildColumnProps } from "@simplicate/ui";
import { useMemo, useCallback } from "react";
import { useDashboardContext } from "../../components/Dashboard";
import { CubeDimension, cubeDimensionToKey } from "../../types";

export type TableWidgetColumn = Parameters<typeof buildColumnProps>[0] & {
  dimension: CubeDimension;
};

type TableWidgetProps = {
  title: string;
  columns: readonly TableWidgetColumn[];
};

export const TableWidget = ({ title, columns }: TableWidgetProps) => {
  const {
    state: { order },
    actions: { orderBy },
    queryResult: { resultSet },
  } = useDashboardContext();
  const dimensions = useMemo(() => columns.map((column) => column.dimension), [columns]);

  const [sortField, sortOrder] = useMemo(() => {
    if (!order) return [undefined, undefined];

    return [order.key, order.direction === "asc" ? 1 : -1] as const;
  }, [order]);

  const value = useMemo(() => {
    if (!resultSet) return [];

    return resultSet.tablePivot().map((row) => {
      const result: Record<string, unknown> = {};

      dimensions.forEach((dimension) => {
        const key = cubeDimensionToKey(dimension);

        result[key] = row[key];
      });

      return result;
    });
  }, [dimensions, resultSet]);

  const handleSort = useCallback(({ sortField }: { sortField: string }) => orderBy(sortField), [orderBy]);

  return (
    <Widget title={title}>
      <Table value={value} sortField={sortField} sortOrder={sortOrder} onSort={handleSort}>
        {columns.map((column) => (
          <Table.Column
            key={cubeDimensionToKey(column.dimension)}
            {...buildColumnProps({
              ...column,
              field: cubeDimensionToKey(column.dimension),
            })}
          />
        ))}
      </Table>
    </Widget>
  );
};
