import { HEADER_DATA_TAG, simplicateApiV2 } from "../components";
import { createTagTypes } from "../tags";
import { Response } from "./types";

type ProjectService = {
  id: string;
  invoice_in_installments: boolean;
};

export type Project = {
  id: string;
  name: string;
  project_number: string;
  organization?: {
    id: string;
    name: string;
  };
  person?: {
    id: string;
    full_name: string;
  };
  project_status: {
    id: string;
    label: string;
  };
  is_reverse_billing: boolean;
  is_invoice_approval: boolean;
};

export const projectTags = createTagTypes({
  tagPrefix: "Project",
  tags: ["entity", "services"],
});

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(projectTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getProject: builder.query<Response<Project>, string>({
      query: (id) => ({
        url: `/projects/project/${id}`,
      }),
      providesTags: [projectTags.entity, HEADER_DATA_TAG],
    }),
    getProjectServices: builder.query<Response<ProjectService[]>, string>({
      query: (id) => ({
        url: `/projects/service?q[project_id]=${id}`,
      }),
      providesTags: [projectTags.services],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProjectQuery, useGetProjectServicesQuery } = endpoints;
