import { skipToken } from "@reduxjs/toolkit/query/react";
import { InvoiceMethod, useGetV3IdentifiersQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { ColumnsTemplate, Icon, Tooltip, Page, Footer, Button, Toggle } from "@simplicate/ui";
import { useNavigate, useParams } from "react-router-dom";
import { CanRegisterHoursErrors } from "./CanRegisterHoursErrors";
import { DefaultServiceSelect } from "./fields/DefaultServiceSelect";
import { DescriptionTextInput } from "./fields/DescriptionTextInput";
import { ExplanationTextArea } from "./fields/ExplanationTextArea";
import { InvoiceableFromDatepicker } from "./fields/InvoiceableFromDatepicker";
import { InvoiceInInstallmentsCheckbox } from "./fields/InvoiceInInstallmentsCheckbox";
import { InvoiceMethodSelect } from "./fields/InvoiceMethodSelect";
import { RevenueGroupSelect } from "./fields/RevenueGroupSelect";
import { TimeframeEndDatePicker } from "./fields/TimeframeEndDatePicker";
import { TimeframeStartDatePicker } from "./fields/TimeframeStartDatePicker";
import { VATCodeSelect } from "./fields/VATCodeSelect";
import { CostTypeGrid } from "./grids/CostTypeGrid";
import { HourTypeGrid } from "./grids/HourTypeGrid";
import styles from "./ProjectServicePage.module.scss";
import { useProjectServiceForm } from "./useProjectServiceForm";

type ProjectServicePageParams = {
  id: string;
};

export const ProjectServicePage = () => {
  const { t } = useTranslation("project_services");
  const navigate = useNavigate();

  const params = useParams<ProjectServicePageParams>();

  const { id } = params;

  const { data: projectV3IdentifierResponse } = useGetV3IdentifiersQuery(id ? { v2Identifiers: [id] } : skipToken);

  const projectId = projectV3IdentifierResponse?.results[0]?.identifier;

  const {
    values,
    errors,
    touched,
    setDefaultService,
    setInvoiceMethod,
    setDescription,
    setExplanation,
    setInvoiceableFrom,
    setInvoiceInInstallments,
    setStartDate,
    setEndDate,
    setRevenueGroup,
    setVatCode,
    setCanRegisterHours,
    setAmountForHourType,
    setTariffForHourType,
    removeNewHourTypeEntry,
    addHourType,
    removeHourTypes,
    handleSubmit,
  } = useProjectServiceForm({ initialValues: undefined, projectId });

  const canHaveInvoiceableFromValue = !values.invoiceInInstallments;
  const isDefaultServiceUndefined = values.defaultService === undefined;

  return (
    <Page
      footer={
        <Footer>
          <Button
            type="submit"
            text={t("buttons.save", { ns: "general" })}
            form="projectservice-form"
            testId="submit-button"
          />
          <Button
            onClick={() => navigate(-1)}
            text={t("buttons.cancel", { ns: "general" })}
            variant="subtle"
            testId="cancel-button"
          />
        </Footer>
      }
    >
      <form onSubmit={handleSubmit} id="projectservice-form">
        <div className={styles.servicePageContainer} data-testid="project-service-page">
          <h2 className={styles.title}>{t("create_project_service_page_title")}</h2>
          <div className={styles.separator} />
          <ColumnsTemplate>
            <ColumnsTemplate.Column columnSpan={4}>
              <div className={styles.column}>
                <div>
                  <h4 className={styles.subTitle}>{t("basic_properties")}</h4>
                </div>
                <DefaultServiceSelect
                  value={values.defaultService}
                  error={errors.defaultService}
                  touched={touched.defaultService}
                  onSelect={setDefaultService}
                />
                <InvoiceMethodSelect
                  value={values.invoiceMethod}
                  error={errors.invoiceMethod}
                  touched={touched.invoiceMethod}
                  onSelect={setInvoiceMethod}
                  disabled={isDefaultServiceUndefined}
                />
                <div className={styles.timeframeLayout}>
                  <div className={styles.timeframeSection}>
                    <h4 className={styles.subTitle}>{t("timeframe")}</h4>
                    <Tooltip message={t("optional")} position="right">
                      <Icon icon="infoCircle" className={styles.infoIcon} />
                    </Tooltip>
                  </div>
                  <div className={styles.timeframeFields}>
                    <div className={styles.timeframeFieldContainer}>
                      <TimeframeStartDatePicker
                        value={values.timeframe.startDate}
                        onChange={setStartDate}
                        error={errors.timeframe?.startDate}
                        touched={touched.timeframe?.startDate}
                        disabled={isDefaultServiceUndefined}
                      />
                    </div>
                    <div className={styles.timeframeFieldContainer}>
                      <TimeframeEndDatePicker
                        value={values.timeframe.endDate}
                        minValue={values.timeframe.startDate}
                        onChange={setEndDate}
                        error={errors.timeframe?.endDate}
                        touched={touched.timeframe?.endDate}
                        disabled={isDefaultServiceUndefined}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ColumnsTemplate.Column>
            <ColumnsTemplate.Column columnSpan={4}>
              <div className={styles.column}>
                <DescriptionTextInput
                  value={values.description}
                  error={errors.description}
                  touched={touched.description}
                  onChange={setDescription}
                  disabled={isDefaultServiceUndefined}
                />
                <ExplanationTextArea
                  value={values.explanation}
                  error={errors.explanation}
                  touched={touched.explanation}
                  onChange={setExplanation}
                  disabled={isDefaultServiceUndefined}
                />
              </div>
            </ColumnsTemplate.Column>
            <ColumnsTemplate.Column columnSpan={4}>
              <div className={styles.advancedPropertiesLayout}>
                <h4 className={styles.subTitle}>{t("advanced_properties")}</h4>
                <RevenueGroupSelect
                  value={values.revenueGroup}
                  error={errors.revenueGroup}
                  touched={touched.revenueGroup}
                  onSelect={setRevenueGroup}
                  disabled={isDefaultServiceUndefined}
                />
                <VATCodeSelect
                  value={values.vatCode}
                  error={errors.vatCode}
                  touched={touched.vatCode}
                  onSelect={setVatCode}
                  disabled={isDefaultServiceUndefined}
                />
              </div>
            </ColumnsTemplate.Column>
          </ColumnsTemplate>
          <div className={styles.separator} />
          <ColumnsTemplate>
            <ColumnsTemplate.Column columnSpan={12}>
              <>
                <div className={styles.gridTitleContainer}>
                  <Toggle
                    testId="can-register-hours-toggle"
                    onChange={setCanRegisterHours}
                    value={values.canRegisterHours ?? false}
                    disabled={isDefaultServiceUndefined}
                    label={<h3 className={styles.sectionTitle}>{t("hour_registration")}</h3>}
                  />
                  <CanRegisterHoursErrors
                    touched={{ hourTypes: touched.hourTypes, canRegisterHours: touched.canRegisterHours }}
                    errors={{ hourTypes: errors.hourTypes, canRegisterHours: errors.canRegisterHours }}
                  />
                </div>

                <div className={!values.canRegisterHours ? styles.disabled : undefined}>
                  <HourTypeGrid
                    value={values.hourTypes}
                    onAmountChange={setAmountForHourType}
                    onTariffChange={setTariffForHourType}
                    onAddNewHourTypeEntry={addHourType}
                    onCancelNewHourTypeEntry={removeNewHourTypeEntry}
                    onRemoveHourTypeEntries={removeHourTypes}
                    disabled={isDefaultServiceUndefined}
                  />
                </div>
              </>
            </ColumnsTemplate.Column>
          </ColumnsTemplate>
          <ColumnsTemplate>
            <ColumnsTemplate.Column columnSpan={8}>
              <h3 className={styles.sectionTitle}>{t("expense_registration")}</h3>
              <CostTypeGrid value={values.costTypes} />
            </ColumnsTemplate.Column>
          </ColumnsTemplate>
          {values.invoiceMethod === InvoiceMethod.fixed_price && (
            <ColumnsTemplate>
              <ColumnsTemplate.Column columnSpan={8}>
                <div className={styles.fixedPriceInvoicingLayout}>
                  <h4 className={styles.subTitle}>{t("invoicing")}</h4>
                  <div className={styles.fixedPriceInvoicingFields}>
                    <InvoiceableFromDatepicker
                      value={canHaveInvoiceableFromValue ? values.invoiceableFrom : undefined}
                      onChange={setInvoiceableFrom}
                      error={errors.invoiceableFrom}
                      touched={touched.invoiceableFrom}
                      disabled={values.invoiceInInstallments}
                    />
                    <InvoiceInInstallmentsCheckbox
                      value={values.invoiceInInstallments}
                      onChange={setInvoiceInInstallments}
                    />
                  </div>
                </div>
              </ColumnsTemplate.Column>
            </ColumnsTemplate>
          )}
          <div className={styles.separator} />
        </div>
      </form>
    </Page>
  );
};
