import { Icon } from "@simplicate/ui";
import { Link } from "react-router-dom";
import { BackButtonBehavior, createPredefinedTargetBackButtonBehavior } from "./BackButtonBehavior";
import styles from "./TopBarBackButton.module.scss";

type TopBarBackButtonProps = {
  /**
   * The target to navigate to when this button is clicked.
   *
   * Alternatively, you can pass any custom hook to determine the back target location based on non-default logic.
   * Especially useful for dynamic back button behavior based on queryable entity data.
   */
  backButtonTarget?: BackButtonBehavior | string;
  testId?: string;
};

export const TopBarBackButton = ({ backButtonTarget, testId }: TopBarBackButtonProps) => {
  const acquireBackNavigationTarget =
    typeof backButtonTarget === "string" || backButtonTarget === undefined
      ? createPredefinedTargetBackButtonBehavior(backButtonTarget)
      : backButtonTarget;

  const { target } = acquireBackNavigationTarget();

  if (!target) {
    return null;
  }

  return (
    // TODO: use Clickable from @simplicate/ui
    <Link to={target.toString()} className={styles.backButton} data-testid={testId}>
      <Icon icon="arrowLeft" />
    </Link>
  );
};
