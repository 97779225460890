import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Button, Table } from "@simplicate/ui";
import { useState } from "react";
import { HourTypeInForm } from "../../../useProjectServiceForm";
import { HourTypeSelect } from "../HourTypeSelect";
import styles from "./HourTypeGridFooter.module.scss";

type HourTypeGridFooterProps = {
  onConfirmNewEntry: (entry: HourTypeInForm) => void;
  onCancelNewEntry: () => void;
  totalAmount: number;
  totalMonetaryValue: Big;
  disabled: boolean;
  value?: HourTypeInForm[];
};

export const HourTypeGridFooter = ({
  onConfirmNewEntry,
  onCancelNewEntry,
  totalAmount,
  totalMonetaryValue,
  disabled,
  value,
}: HourTypeGridFooterProps) => {
  const { t } = useTranslation("project_services");
  const [isAddingHourType, setIsAddingHourType] = useState(false);

  return (
    <>
      {isAddingHourType && (
        <Table.Footer variant="secondary">
          <HourTypeSelect
            hourTypesInForm={value}
            onConfirmNewEntry={(entry) => {
              setIsAddingHourType(false);
              onConfirmNewEntry(entry);
            }}
            onCancelNewEntry={() => {
              setIsAddingHourType(false);
              onCancelNewEntry();
            }}
          />
        </Table.Footer>
      )}
      <Table.Footer testId="hour-type-grid-footer" className={styles.footer}>
        <div>
          <Button
            testId="add-new-hour-type"
            text={t("add_hour_type_button")}
            variant="secondary"
            onClick={() => setIsAddingHourType(true)}
            disabled={isAddingHourType || disabled}
            iconProps={{ icon: "plus" }}
          />
        </div>
        <span>Budget</span>
        <span className={styles.alignRight}>{totalAmount}</span>
        <span>{t("hour", { count: totalAmount })}</span>
        <span className={styles.alignRight}>
          <CurrencyFormat value={totalMonetaryValue.toString()} displayType="input" size="small" textAlign="right" />
        </span>
      </Table.Footer>
    </>
  );
};
