import { PropsWithChildren } from "react";
import styles from "./ColumnsTemplate.module.scss";

const COLUMN_SPAN_VAR: string = "--column-span";

type ColumnProps = {
  columnSpan: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  testId?: string;
};

const ColumnsTemplate = ({ children, testId }: PropsWithChildren<{ testId?: string }>) => {
  return (
    <div data-testid={testId} className={styles.container}>
      {children}
    </div>
  );
};

const Column = ({ columnSpan, children, testId }: PropsWithChildren<ColumnProps>) => {
  return (
    <div data-testid={testId} className={styles.column} style={{ [COLUMN_SPAN_VAR]: columnSpan }}>
      {children}
    </div>
  );
};

ColumnsTemplate.Column = Column;

export { ColumnsTemplate };
