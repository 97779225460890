import { Clickable, Icon, ModuleIcon, Tag } from "@simplicate/ui";
import { Module, asModuleTheme } from "@simplicate/utils";
import classNames from "classnames";
import { ComponentProps } from "react";
import { ModuleTag } from "../ModuleTag";
import styles from "./NavCard.module.scss";

type NavCardProps = {
  dashboardId: string;
  description: string;
  icon?: ComponentProps<typeof Icon>["icon"];
  module: Module;
  title: string;
};

export const NavCard = ({ dashboardId, description, icon, module, title }: NavCardProps) => {
  const moduleClass = asModuleTheme(module);

  return (
    <Clickable to={dashboardId} className={classNames(moduleClass, styles.navCard)}>
      <div className={styles.accent}>{icon ? <Icon icon={icon} /> : <ModuleIcon module={module} />}</div>
      <h3 className={styles.header}>{title}</h3>
      <p className={styles.body}>{description}</p>
      <div className={styles.footer}>
        <ModuleTag module={module} />
        {/* TODO: Remove this coming soon tag */}
        <Tag text="Coming soon" variant="light" size="small" />
      </div>
    </Clickable>
  );
};
